<template>
  <div
    v-if="isMobileScreen"
    class="fixed top-0 bottom-0 left-0 right-0 bg-black opacity-60 z-[600]"
  ></div>
  <div
    ref="container"
    class="w-full md:w-[330px] h-fit rounded-t-alt-xl md:rounded-alt-xl py-sm z-[610] bg-white"
    :class="[
      showArrow && !isMobileScreen ? 'arrow' : '',
      isMobileScreen
        ? 'fixed left-0 bottom-0'
        : `absolute shadow-[0px_3px_20px] shadow-border-light ${positionStyles}`,
    ]"
  >
    <div
      class="flex flex-row justify-between mx-md my-sm"
      :class="{ 'md:hidden': showHeadlineOnlyMobile }"
    >
      <p class="font-bold text-alt-2xl text-text-base font-deco-alt mb-xs">
        <slot name="headline" />
      </p>
      <button
        type="button"
        class="text-xl cursor-pointer text-border-dark md:hidden"
        @click="$emit('close')"
      >
        <FaIcon icon-class="fas fa-xmark" />
      </button>
    </div>

    <slot name="content" />
  </div>
</template>
<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
import { SSR_safe_mq_breakpointIsMobile } from '~/injectionSymbols';

const props = defineProps({
  showArrow: {
    type: Boolean,
    required: false,
    default: false,
  },
  showHeadlineOnlyMobile: {
    type: Boolean,
    required: false,
    default: false,
  },
  topPosition: {
    type: String as PropType<'top-md' | 'top-lg' | 'top-xl' | ''>,
    required: false,
    default: 'top-lg',
  },
  isSettingsPopup: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const container = ref<HTMLElement | null>(null);
const isMobileScreen = inject(SSR_safe_mq_breakpointIsMobile);
const positionStyles = ref('');

onClickOutside(container, () => emit('close'));

onMounted(() => {
  positionStyles.value = setPostion();
});

function getPlacementOfPopUp(): string {
  if (!container.value) return;

  if (props.isSettingsPopup) return 'settingsOverlay';

  const element = container.value?.getBoundingClientRect();

  const outOnRightSide =
    element.right > (window.innerWidth || document.documentElement.clientWidth);

  if (
    element.bottom >
    (window.innerHeight || document.documentElement.clientHeight)
  ) {
    // Bottom and left side out of viewport
    if (element.left < 0) return 'top-start';
    // Bottom and right side out of viewport
    if (outOnRightSide) return 'top-end';
    // Bottom is out of viewport
    return 'top';
  }

  if (outOnRightSide) {
    // Right side is out of viewport
    return 'bottom-end';
  }

  if (element.left < 0) {
    // Left side is out of viewport
    return 'bottom-start';
  }

  return 'bottom';
}

function setPostion() {
  if (!container.value) return;
  switch (getPlacementOfPopUp()) {
    case 'settingsOverlay':
      return 'bottom right-0 ' + props.topPosition;
    case 'top':
      return `!bottom-lg -translate-x-[calc(50%-20px)] arrow-bottom arrow-center`;
    case 'top-end':
      return `!bottom-lg -right-xs arrow-bottom arrow-right`;
    case 'top-start':
      return `!bottom-lg -left-xs arrow-bottom arrow-left`;
    case 'bottom-end':
      return (
        'arrow-top arrow-right before:!top-[-4px] -right-xs ' +
        props.topPosition
      );
    case 'bottom-start':
      return 'arrow-top arrow-left -left-xs ' + props.topPosition;
    case 'bottom':
    default:
      return (
        'arrow-top arrow-center -translate-x-[calc(50%-20px)] ' +
        props.topPosition
      );
  }
}
</script>

<style scoped lang="postcss">
.arrow {
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &.arrow-top::before {
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent white transparent;
    top: -8px;
  }
  &.arrow-bottom::before {
    border-width: 8px 8px 0 8px;
    border-color: white transparent transparent transparent;
    bottom: -8px;
  }
  &.arrow-center::before {
    left: calc(50% - 8px);
  }
  &.arrow-left::before {
    left: 22px;
  }
  &.arrow-right::before {
    right: 15px;
    left: unset;
  }
}
</style>
